<template>
  <tr>
    <td class="px-2 first:pl-5 last:pr-5 py-3">
      <div class="font-medium text-sky-500">{{product.name}}</div>
    </td>        
    <td class="px-2 first:pl-5 last:pr-5 py-3">
      <div class="font-medium text-slate-800 dark:text-slate-100">{{product.description}}</div>
    </td>
    <td class="px-2 first:pl-5 last:pr-5 py-3">
      <div class="font-medium text-slate-800 dark:text-slate-100">{{product.priority}}</div>
    </td>
    <td v-if="canEdit || canDelete" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
      <div class="space-x-1">
        <button v-if="canEdit" class="text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 rounded-full" @click="editProduct(product)">
          <span class="sr-only">Edit</span>
          <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
              <path d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z" />
          </svg>
        </button>
        <DeleteButton v-if="canDelete" :clickHandler="() => deleteProduct(product.id)" />
      </div>
    </td>
  </tr>  
</template>

<script>
import Swal from 'sweetalert2';
import DeleteButton from '@/components/buttons/DeleteButton.vue'

export default {
  name: 'ProductsTableItem',
  props: {
    product: Object,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    canDelete: {
      type: Boolean,
      default: function(){
        return false
      }
    }
  },
  components: {
    DeleteButton
  },
  methods: {
    editProduct(product) {    
      this.$router.push(`/products/${product.id}`);
    },
    deleteProduct(id) {
      Swal.fire({
        title: "Eliminar Producto",
        text: "Confirma que deseas eliminar el producto",
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed ) {
        this.$emit('delete-product', id);
      }
    });
    },
  }
}
</script>