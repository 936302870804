import Swal from 'sweetalert2';
import { postData } from '@/utils/postData.js';

export async function rejectProspect(context) {
    Swal.fire({
      title: 'Rechazar prospecto',
      text: "Confirma que deseas rechazar el prospecto.",
      input: "select",
      inputOptions: {
        "Cliente no califica": "Cliente no califica",
        "Error de alta": "Error de alta",
        "Cliente fuera del area de cobertura": "Cliente fuera del area de cobertura",
      },
      inputPlaceholder: "Seleccione un motivo del rechazo",
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Es necesario rellenar el motivo del rechazo";
        }
      }
    }).then(async (result) => {
        if (result.isConfirmed) {
          context.isLoading = true;
          const response = await postData(`prospects/reject/${context.prospectId}`, {
            rejectedReason: result.value
          }, context, false);
          if (!response) {
            return;
          };
          Swal.fire({
            icon: 'success',
            title: 'Exito!',
            text: 'Prospecto rechazado con exito',
          });
          context.isLoading = false;
          this.$router.push('/prospects');
        }
    });
};