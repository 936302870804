<template>
  <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{{label}}</div>
  <div class="mt-4 mb-8">
    <vue-slider v-model="value" :enable-cross="false"
      :process-style="{ backgroundColor: '#6a68f1' }"
      :tooltip-style="{ backgroundColor: 'blue' }"
      :dot-style="{ backgroundColor: '#7f0680' }"
      :rail-style="{ backgroundColor: 'gray' }"
      :label-style="{ color: 'white'}"
      :marks="marks"
      :max="Math.round(this.max + 1)"
      :min="Math.round(this.min)"
      :interval="this.step"
      :tooltip-formatter="formatNumber"
      :label-formatter="formatNumber"
      @change="onChange" 
    ></vue-slider>
  </div>
</template>
  
<script>
    import VueSlider from 'vue-slider-component'
    
    export default {
      name: 'SliderFilter',
      components: {
        VueSlider,
      },
      props: {
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1
        },
        label: String,
        defaultValues_: Array
      },
      data () {
      return {
        marks: [this.min, this.max],
        value: this.defaultValues_
      }
    },
    methods: {
      formatNumber(value) {
        return value.toLocaleString(); // Adds commas
      },
      onChange(value, index){
        this.$emit('update:filter', value);
      }
    } 
  }
</script>