import Swal from 'sweetalert2';
import api from '@/interceptors/axiosInterceptos'; 
import { getProperty } from '@/utils/getProperty.js';
import { initDB } from '../../middleware/middleware';


export async function fetchData(
  endpoint,
  targetProperty,
  storageKey,
  context,
  loading = true,
  forceLoading = false,
  propertyName = 'data',
  append = false,
  isPaginated = false,
  limit = 100,
  offset = 0
) {
  const db = await initDB();
  
  try {
    const initialLoadCompleted = localStorage.getItem(`initialLoadCompleted${targetProperty}`) === 'true';
    if (loading && (!initialLoadCompleted || forceLoading)) {
      context.isLoading = true;
    }

    const token = localStorage.getItem('token');
    const headers = { "authorization": `Bearer ${token}` };

    const paginatedEndpoint = isPaginated ? `${endpoint}/${limit}/${offset}` : endpoint;
    const response = await api.get(`${process.env.API_URL}/${paginatedEndpoint}`, { headers });
    const dataFetched = getProperty(response, propertyName);
    const isArrayResponse = Array.isArray(dataFetched);
    if (isArrayResponse) {
      context[targetProperty] = dataFetched;
      const tx = db.transaction(storageKey, 'readwrite');
      const store = tx.objectStore(storageKey);

      await store.clear();
      for (const item of dataFetched) {
        await store.put(item);
      }
      await tx.done;
      if(isPaginated) {
        const count = getProperty(response, 'data.count');
        context[`count${targetProperty}`] = count
        localStorage.setItem(`count${targetProperty}`, count);
      }
    } else {
      context[targetProperty] = dataFetched;
      localStorage.setItem(storageKey, JSON.stringify(dataFetched));
    }  

    localStorage.setItem(`initialLoadCompleted${targetProperty}`, 'true');

    if (loading && (!initialLoadCompleted || forceLoading)) {
      context.isLoading = false;
    }
    return context[targetProperty];
  } catch (error) {
    console.log('here', storageKey)
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: error.response?.data?.detail ?? error.message
    });
    context.isLoading = false;
  }
}
