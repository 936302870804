import { getUniqueValuesFilter } from '@/utils/getUniqueValuesFilter.js';

export function setFiltersMethod(context, prospects, storageKey, storeValues){
    const { uniqueValues: sources, filter: selectedUsersSource } = getUniqueValuesFilter(context.sources, 'name');
    const { uniqueValues: users, filter: selectedUsersAgent } = getUniqueValuesFilter(context.users, 'username');
    const { uniqueValues: uniqueProducts, filter: selectedProducts } = getUniqueValuesFilter(context.products, 'name');
    context.filtersConfig[0].filters[0].options = uniqueProducts;
    context.filtersConfig[4].filters[0].options = users;
    context.filtersConfig[4].filters[1].options = sources;
    const balances = prospects.map(obj => obj.balance).filter(balance => typeof balance === 'number');
    const points = prospects.map(obj => obj.infonavitPoints).filter(infonavitPoints => typeof infonavitPoints === 'number');
    const quotedweeks = prospects.map(obj => obj.quotedWeeks).filter(quotedWeeks => typeof quotedWeeks === 'number');
    const discountedweeks = prospects.map(obj => obj.discountedWeeks).filter(discountedWeeks => typeof discountedWeeks === 'number');
    const reintegratedweeks = prospects.map(obj => obj.reintegratedWeeks).filter(reintegratedWeeks => typeof reintegratedWeeks === 'number');
    context.filtersConfig[5].filters[0].values = [Math.min(...balances.length === 0 ? [0] : balances), Math.max(...balances.length === 0 ? [0] : balances)];
    context.filtersConfig[5].filters[1].values = [Math.min(...points.length === 0 ? [0] : points), Math.max(...points.length === 0 ? [0] : points)];
    context.filtersConfig[6].filters[1].values = [Math.min(...quotedweeks.length === 0 ? [0] : quotedweeks), Math.max(...quotedweeks.length === 0 ? [0] : quotedweeks)];
    context.filtersConfig[6].filters[2].values = [Math.min(...discountedweeks.length === 0 ? [0] : discountedweeks), Math.max(...discountedweeks.length === 0 ? [0] : discountedweeks)];
    context.filtersConfig[6].filters[3].values = [Math.min(...reintegratedweeks.length === 0 ? [0] : reintegratedweeks), Math.max(...reintegratedweeks.length === 0 ? [0] : reintegratedweeks)];
    const { uniqueValues: callstatus, filter: selectedCallStatusFilter } = getUniqueValuesFilter(prospects, 'callStatus');
    const { uniqueValues: appointmentstatus, filter: selectedAppointmentStatusFilter } = getUniqueValuesFilter(prospects, 'appointmentStatus');
    const { uniqueValues: closingstatus, filter: selectedClosingStatusFilter } = getUniqueValuesFilter(prospects, 'closingStatus');
    const { uniqueValues: creditstatus, filter: selectedCreditStatusFilter } = getUniqueValuesFilter(prospects, 'creditStatus');
    const { uniqueValues: creditsituation, filter: selectedCreditSituationFilter } = getUniqueValuesFilter(prospects, 'creditSituation');
    const { uniqueValues: creditype, filter: selectedCreditTypeFilter } = getUniqueValuesFilter(prospects, 'creditType');
    context.filtersConfig[3].filters[0].options = callstatus;
    context.filtersConfig[3].filters[1].options = appointmentstatus;
    context.filtersConfig[3].filters[2].options = closingstatus;
    context.filtersConfig[7].filters[0].options = creditsituation;
    context.filtersConfig[7].filters[1].options = creditype;
    context.filtersConfig[7].filters[2].options = creditstatus;
    context.filters.selectedCallStatusFilter = selectedCallStatusFilter;
    context.filters.selectedAppointmentStatusFilter = selectedAppointmentStatusFilter;
    context.filters.selectedClosingStatusFilter = selectedClosingStatusFilter;
    context.filters.selectedCreditStatusFilter = selectedCreditStatusFilter;
    context.filters.selectedCreditSituationFilter = selectedCreditSituationFilter;
    context.filters.selectedCreditTypeFilter = selectedCreditTypeFilter;
    context.filters.selectedUsersSource = selectedUsersSource;
    context.filters.selectedUsersAgent = selectedUsersAgent;  
    context.filters.selectedProducts = selectedProducts; 
    
    const filters = JSON.parse(localStorage.getItem(storageKey)) || null;
    if(!filters && storeValues){
        localStorage.setItem(storageKey, JSON.stringify(context.filters))
        localStorage.setItem(`${storageKey}Original`, JSON.stringify(context.filters))
    } else if(filters) {
        context.filters = filters
    }
    context.componentKey++  
}