<template>
    <BasePage>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <div v-else class="w-full max-w-9xl mx-auto">
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
            <!-- Left: Title -->
            <PageTitle :title="`Clientes ✨`" />

            <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center">
              <BoardToggle :is-board="boardModeClients" @update:model="changeBoardModeClients"/>
            </div>            
        </div>
        <div v-if="showFilters" class="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9">
          <!-- Sidebar -->
          <FiltersSidebar :key="this.componentKey" v-if="!isLoading && showFilters" 
              :filter-config_="this.filtersConfig"
              :filters_="filters" @resetFilters:value="resetFilters"
              @update:checkboxfilters="updateCheckBoxFilter($event, this)"
              @update:filters="updateFilter($event, this)"
              @update:updateDateFilter="updateDateFilter($event, this)"
              @update:selectfilters="updateCheckBoxFilter($event, this)"
            />
          <div class="w-full" >
            <ProspectsTable v-if="!isLoadingProspects" :title="'Clientes'"
            :is-todo="isTodo" :is-in-progress="isInProgress" :is-completed="isCompleted" :is-rejected="isRejected"
            :is-board="boardModeClients"  :can-delete="showDelete" :can-edit="showEdit" :can-visualize="showVisualize"
            :filters="filters" :count="count" :current-page="filters.currentPage"
            :sources="this.sources" :users="this.users" @update:value="updateFilter($event, this)"
            :prospects="this.clients" :items-per-page="100" @delete-p="deleteP"/>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
          </div>
        </div>
      </div>
    </BasePage>
  </template>

<script>
    import { fetchData } from '@/utils/fetchData.js';
    import { setDataInStore } from '@/utils/loadFromLocalStorage.js';
    import { fetchDataWithPost } from '@/utils/fetchDataWithPost.js';
    import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';
    import { setFiltersMethod } from '@/services/prospects/prospectsPageServices';
    import { prospectsPageComponentData } from '@/services/prospects/prospectsPageComponentData';
  
    import BasePage from '@/components/BasePage.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import BoardToggle from '@/components/BoardToggle.vue';
    import FiltersSidebar from '@/components/FiltersSidebar.vue';
    import ProspectsTable from '@/partials/prospects/ProspectsTable.vue';
    export default {
        name: 'ClientsPage',
        components: {
          BasePage,
          PageTitle,
          BoardToggle,
          ProspectsTable, 
          FiltersSidebar,
        },
        data(){
          return prospectsPageComponentData()
        },
        watch: {
          filters: {
            async handler(newFilters) {
              this.isLoadingProspects = true;
              // Cancel the previous request if it exists
              if (this.abortController) {
                this.abortController.abort();
              }

              // Create a new AbortController for this request
              this.abortController = new AbortController();
              const { signal } = this.abortController;

              let cancelLoading = true;

              try {
                const data = await fetchDataWithPost(
                  `prospects/${false}`,
                  "clients",
                  "clients",
                  this,
                  newFilters,
                  false,
                  false,
                  "data.data",
                  false,
                  true,
                  100,
                  newFilters.offset,
                  { signal } // Pass the signal to allow request cancellation
                );

                this.fetchedProspects = data;
              } catch (error) {
                if (error.name === 'CanceledError') {
                  cancelLoading = false
                  console.log("Previous request aborted.");
                } else {
                  console.error("Error fetching prospects:", error);
                }
              } finally {
                if (this.abortController.signal.aborted === false && cancelLoading) {
                  this.isLoadingProspects = false;
                }
                console.log(new Date())
              }
            },
            deep: true, // Watches for nested changes in filters
            immediate: false, // Fetch data when the component is mounted
          },
        },
        methods: {
            isTodo(prospect) {
              return !prospect.isRejected && prospect.appointmentStatus === 'TODO' &&
                prospect.callStatus === 'TODO' &&
                prospect.closingStatus === 'TODO';
            },
            isInProgress(prospect) {
              const { appointmentStatus, callStatus, closingStatus, isRejected } = prospect;

              // Check if any status is neither 'TODO' nor 'DONE'
              const hasInprogressStatus = [appointmentStatus, callStatus, closingStatus].some(
                status => status !== 'TODO' && status !== 'DONE'
              );

              // Check if any status is 'DONE' while others are still 'TODO'
              const isPartiallyComplete = (
                (appointmentStatus === 'DONE' && (callStatus === 'TODO' || closingStatus === 'TODO')) ||
                (callStatus === 'DONE' && (appointmentStatus === 'TODO' || closingStatus === 'TODO')) ||
                (closingStatus === 'DONE' && (appointmentStatus === 'TODO' || callStatus === 'TODO'))
              );

              return (hasInprogressStatus || isPartiallyComplete) && !isRejected;
            },
            isCompleted(prospect) {
              return prospect.appointmentStatus === 'DONE' && 
                prospect.callStatus === 'DONE' && 
                prospect.closingStatus === 'DONE' && !prospect.isRejected
            },
            isRejected(prospect) {
              return prospect.isRejected;
            },
            resetFilters(updated){
              const original = JSON.parse(localStorage.getItem('clientsFiltersOriginal')) || null;
              this.filters = original
              localStorage.setItem('clientsFilters', JSON.stringify(this.filters))
              this.componentKey++
            },
            updateFilter(updated, context){
              context.filters[updated.propName] = updated.value;
              localStorage.setItem('clientsFilters', JSON.stringify(context.filters))
            },
            updateDateFilter(updated, context){
              context.filters[updated.propName] = updated.value !==  null ? updated.value['0'] : null;
              localStorage.setItem('clientsFilters', JSON.stringify(context.filters))
            },
            updateCheckBoxFilter(updated, context){
              context.filters[updated.propName] = { ...updated.value };
              localStorage.setItem('clientsFilters', JSON.stringify(context.filters))
            },
            async deleteP(id){
                const updated = this.clients.filter((prospect) => prospect.id !== id);
                await setDataInStore('clients', updated)
                this.clients = this.clients.filter((prospect) => prospect.id !== id)
            },
            changeBoardModeClients(updated){
                this.boardModeClients = updated;
                localStorage.setItem('boardModeClients', updated);
            },
            async getData() {
              this.isLoading = true
              console.log(new Date())
              Promise.all([
                fetchData(`sources`, 'sources', 'sources', this, false),
                // fetchData(`adds`, 'adds', 'adds', this, false),
                fetchData(`users`, 'users', 'users', this, false),
                fetchData(`products/see`, 'products', 'products', this, false),
                // this request is here to allow for some of the selected to populate
                fetchDataWithPost(`prospects/${false}`, 'clients', 'clients', this, this.filters, false, false, 'data.data', false, true, 100, 0),
              ]).then(async (results) =>{
                this.setFilters(true);
                this.isLoading = false
                console.log(new Date())
              })
            },
            setFilters(storeValues) {
              setFiltersMethod(this, this.clients, 'clientsFilters', storeValues)
            }
        },
        async created() {
          hasSpecificPermission('showFilters', 'page-filter', ["view_prospects_filter"], this)
          hasSpecificPermission('showAdd', 'add', ["add_prospect"], this)
          hasSpecificPermission('showEdit', 'edit', ["edit_prospect"], this)
          hasSpecificPermission('showDelete', 'delete', ["delete_prospect"], this)
          hasSpecificPermission('showVisualize', 'page', ["view_prospects_detail_page"], this)
          this.boardMode = JSON.parse(localStorage.getItem('boardMode')) ?? true;
          this.getData();
        },
    }

</script>