<template>
    <div class="grow">
      <!-- Panel body -->
      <div class="p-6 space-y-6">
        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Datos de prospeccion</h2>
        <span v-for="category in inputConfigList" :key="category.name">
            <h1 class="block my-4">
                {{ category.name }}
            </h1>
            <div class="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-12" :key="this.componentKey">
                <div v-for="(value, key) in category.inputConfig" :key="category.inputConfig[key]?.label" :class="category.inputConfig[key]?.show || false ? category.inputConfig[key].class : 'hidden'">
                    <span v-if="category.inputConfig[key]?.show || false">
                        <label class="block text-sm font-medium mb-1">
                            {{ $t(category.inputConfig[key]?.label || key) }}:
                        </label>
                        <input v-if="category.inputConfig[key].type !== 'options'"
                            :type="category.inputConfig[key].type" :disabled="!canEdit"
                            :step="category.inputConfig[key].step"
                            :min="0"
                            :placeholder="category.inputConfig[key].placeholder"
                            class="form-input w-full"
                            v-model="formattedFormData[key]"
                        />
                        <DropdownFull v-else
                        :propname="'name'" :disabled="!canEdit"
                        :dropdownOptions="category.inputConfig[key].options" 
                        :init-value="category.inputConfig[key].options.findIndex(c => c.value == formattedFormData[key])"
                        :model="formattedFormData[key]" @update:model="updateDropDown($event, key)" />
                    </span>  
                </div>
            </div>
        </span>
        <SubmitMainButton v-if="canEdit" :is-loading="isLoading" :show-cancel-button="true" :click-handler="forceRerender" />
    </div>
  </div>  
</template>

<script>
    import {states} from '@/utils/states.js';

    import DropdownFull from '@/components/DropdownFull.vue';
    import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
    export default {
        name: 'ProspectionDataPanel',
        components: {  
            DropdownFull,
            SubmitMainButton,
        },
        props: {
            isLoading: Boolean,
            prospect: Object,
            canEdit: {
                type: Boolean,
                default: function(){
                    return false
                }
            },
        },
        computed: {
            formattedFormData: {
            get() {
                return {
                    ...this.formData,
                    dob: this.formatDate(this.formData.dob),
                    startLaborActivityDate: this.formatDate(this.formData.startLaborActivityDate),
                    endLaborActivityDate: this.formatDate(this.formData.endLaborActivityDate),
                    creditCloseDate: this.formatDate(this.formData.creditCloseDate),
                };
            },
            set(newFormData) {
                this.formData = {
                    ...newFormData,
                    dob: this.unformatDate(newFormData.dob),
                    startLaborActivityDate: this.unformatDate(newFormData.startLaborActivityDate),
                    endLaborActivityDate: this.unformatDate(newFormData.endLaborActivityDate),
                    creditCloseDate: this.unformatDate(newFormData.creditCloseDate),
                };
            },
            },
        },
        data(){
            return {
                componentKey: 0,
                states: states,
                formData: this.prospect,
                inputConfigList: [
                    {
                        name: 'General',
                        inputConfig: {
                            curp: { type: 'text', step: null, placeholder: null, label: 'labels.curp', show: true, class: 'col-span-2' },
                            dob: { type: 'date', step: null, placeholder: null, label: 'labels.dob', show: true, class: 'col-span-2' },
                            nss: { type: 'text', step: null, placeholder: 'NSS', label: 'labels.nss', show: true, class: 'col-span-2' },
                            srvBalance: { type: 'text', step: null, placeholder: 'SRV', label: 'labels.srvBalance', show: true, class: 'col-span-2' },
                            afore: { type: 'text', step: null, placeholder: 'AFORE', label: 'labels.afore', show: true, class: 'col-span-2' },
                            // Add other configurations as needed
                        },
                    },
                    {
                        name: 'Subcuenta Infonavit',
                        inputConfig: {
                            balance: { type: 'number', step: '1', placeholder: '0', label: 'labels.balance', show: true, class: 'col-span-2' },
                            sar97: { type: 'number', step: '1', placeholder: '0', label: 'labels.sar97', show: true, class: 'col-span-2' },
                            sar92: { type: 'number', step: '1', placeholder: '0', label: 'labels.sar92', show: true, class: 'col-span-2'},
                            infonavitPoints: { type: 'number', step: '1', placeholder: '0', label: 'labels.balance', show: true, class: 'col-span-2' },
                        }
                    },
                    {
                        name: 'IMSS',
                        inputConfig: {
                            quotedWeeks: { type: 'number', step: '1', placeholder: '0', label: 'labels.quotedWeeks', show: true, class: 'col-span-2' },
                            discountedWeeks: { type: 'number', step: '1', placeholder: '0', label: 'labels.discountedWeeks', show: true, class: 'col-span-2' },
                            reintegratedWeeks: { type: 'number', step: '1', placeholder: '0', label: 'labels.reintegratedWeeks', show: true, class: 'col-span-2' },
                            law73: { type: 'options', step: null, placeholder: null, label: 'labels.isLaw73', show: true, class: 'col-span-3 md:col-span-4', options: [{'name': 'Si', value: true}, {'name': 'No', value: false}]},
                            currentlyActive: { type: 'options', step: null, placeholder: null, label: 'labels.isActive', show: true, class: 'col-span-4', options: [{'name': 'Activo', value: true}, {'name': 'Inactivo', value: false}] },
                            startLaborActivityDate: { type: 'date', step: null, placeholder: null, label: 'labels.startLaborActivityDate', show: true, class: 'col-span-2' },
                            endLaborActivityDate: { type: 'date', step: null, placeholder: null, label: 'labels.endLaborActivityDate', show: true, class: 'col-span-2' },
                        }
                    },
                    {
                        name: 'Credito',
                        inputConfig: {
                            creditSituation: { type: 'text', step: null, placeholder: null, label: 'labels.creditSituation', show: true, class: 'col-span-3 md:col-span-4' },
                            creditStatus: { type: 'text', step: null, placeholder: null, label: 'labels.creditStatus', show: true, class: 'col-span-3' },
                            creditType: { type: 'text', step: null, placeholder: null, label: 'labels.creditType', show: true, class: 'col-span-3' },
                            creditCloseDate: { type: 'date', step: null, placeholder: null, label: 'labels.creditCloseDate', show: true, class: 'col-span-2' },
                            creditOpenDate: { type: 'date', step: null, placeholder: null, label: 'labels.creditOpenDate', show: true, class: 'col-span-2' },
                            infonavitCreditNumber: { type: 'text', step: null, placeholder: 'Infonavit Credit Number', label: 'labels.infonavitCreditNumber', show: true, class: 'col-span-2' },
                            infonavitDebt: { type: 'number', step: '1', placeholder: '0', label: 'labels.infonavitDebt', show: true, class: 'col-span-2' },
                            infonavitCreditCurrency: { type: 'text', step: null, placeholder: 'PESOS', label: 'labels.infonavitCreditCurrency', show: true, class: 'col-span-2' },
                            unpaidMonths: { type: 'number', step: '1', placeholder: '0', label: 'labels.unpaidMonths', show: true, class: 'col-span-2' },
                            infonavitRFC: { type: 'text', step: null, placeholder: 'RFC', label: 'labels.infonavitRFC', show: true, class: 'col-span-2' },
                            infonavitEmail: { type: 'email', step: null, placeholder: 'john@doe.com', label: 'labels.infonavitEmail', show: true, class: 'col-span-2', options: null, propname: null, searchKey: null },
                            infonavitPhone: { type: 'tel', step: null, placeholder: '3323130044', label: 'labels.infonavitPhone', show: true, class: 'col-span-2', options: null, propname: null, searchKey: null },
                        }
                    }
                ]
            }
        },
        methods: {
            updateDropDown(updated, key) {
                this.formattedFormData[key] = updated.value;
            },
            async forceRerender() {
                this.$emit('forceRender');
            },
            save(){
                this.$emit('save', this.formattedFormData);
            },
            updateFormData(updated){
                
            },
            formatDate(dateStr) {
                if (!dateStr) return null;
                const date = new Date(dateStr);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
        },   
        async created(){
            this.prospectId = this.$route.params.id;
        }     
    }
</script>