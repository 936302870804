<template>
  <ModalBasic id="preview-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" :title="'Preview Contrato'" :size="'max-w-2xl'" :document-row="true">

<vue-document-editor class="editor h-[32rem] overflow-y-auto" ref="editor"
v-model:content="content" :content-editable="false"
:overlay="overlay"
:zoom="zoom"
:page_format_mm="page_format_mm"
:page_margins="page_margins"
:display="display" />
</ModalBasic>
    <BasePage>
      <span v-if="isLoading">
            <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <span v-else>
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
          <PageTitle :title="'Contratos ✨'" />
            <div v-if="showAdd" class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center">
                <AddMainButton :title="'Agregar contrato'" :clickHandler="() => {this.$router.push('/products/create-contract');}" />
            </div>
        </div>
        
        <GenericTable
            :title="`Contratos`"
            :items="contracts"
            :columns="columns"
            :itemsPerPage="contracts.length"
          >
          <template v-slot:table-rows="{ items }">
            <tr v-for="element in items" :key="element.id">
              
                <td class="px-2 first:pl-5 last:pr-5 py-3">{{ element.name }}</td>
                <td class="px-2 first:pl-5 last:pr-5 py-3">{{ element.description }}</td>
                <td v-if="showDelete" class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px text-center">
                  <span class="content-center"><button @click.prevent.stop="previewContract(element)"><font-awesome-icon class="h-4" :icon="['fas', 'eye']" /></button></span>
                    <DeleteButton :clickHandler="() => deleteElement(element.id)" />
                </td>
            </tr>
          </template>
        </GenericTable>
      </span>
    </BasePage>
</template>
  
<script>
  import { fetchData } from '@/utils/fetchData.js';
  import { deleteData } from '@/utils/deleteData.js';
  import {hasSpecificPermission} from '@/utils/hasSpecificPermission.js';
  import { loadFromLocalStorage, setDataInStore } from '@/utils/loadFromLocalStorage.js';

  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import ModalBasic from '@/components/ModalBasic.vue';
  import GenericTable from '@/components/GenericTable.vue'
  import DeleteButton from '@/components/buttons/DeleteButton.vue'
  import AddMainButton from '@/components/buttons/AddMainButton.vue'
  import VueDocumentEditor from '@/pages/DocumentEditor/DocumentEditor.vue';
  
  export default {
    name: 'ContractsPage',
    components: {
      BasePage,
      PageTitle,
      ModalBasic,
      GenericTable,
      DeleteButton,
      AddMainButton,
      VueDocumentEditor,
    },
    data(){
        return {
      zoom: 0.8,
      zoom_min: 0.10,
      zoom_max: 5.0,
      page_format_mm: [256, 330],
      page_margins: "0.98in 1.18in",
      display: "grid", // ["grid", "vertical", "horizontal"]
      content: [],
          isLoading: false,
          isDeleting: false,
          showAdd: false,
          showEdit: false,
          showDelete: false,
          deletingId: '',
          modalOpen: false,
          contracts: [],
          columns: this.showDelete ? 
            [
              { label: 'Nombre', key: 'name' },
              { label: 'Descripcion', key: 'description' },
              { label: 'Actions', key: 'actions' }
            ]
          : [
            { label: 'Nombre', key: 'name' },
            { label: 'Descripcion', key: 'description' },
          ],
        }
    },
    methods: {
      overlay (page, total) {
      // Add page numbers on each page
      let html = '<div style="position: absolute; bottom: 8mm; ' + ((page % 2) ? 'right' : 'left') + ': 10mm">Page ' + page + ' of ' + total + '</div>';

      // Add custom headers and footers from page 3
      // if(page >= 3) {
      //     html += '<div style="position: absolute; left: 0; top: 0; right: 0; padding: 3mm 5mm; background: rgba(200, 220, 240, 0.5)"><strong>MYCOMPANY</strong> example.com /// This is a custom header overlay</div>';
      //     html += '<div style="position: absolute; left: 10mm; right: 10mm; bottom: 5mm; text-align:center; font-size:10pt">MY COMPANY - example.com /// This is a custom footer overlay</div>';
      // }
      return html;
    },
        async deleteElement(id){
            const response = await deleteData('contracts', id, this, 'El contrato se a eliminado exitosamente.', true, true)
            if(!response){
                return
            }
            const updated = this.contracts.filter((c) => c.id !== id);
            await setDataInStore('contracts', updated)
            this.contracts = this.contracts.filter((c) => c.id !== id)
        },
        previewContract(contract){
          this.modalOpen = true;
          this.content = contract.content
        }
    },
    async created(){
      hasSpecificPermission('showAdd', 'add', ["add_contracts"], this)
      hasSpecificPermission('showEdit', 'edit', ["edit_contracts"], this)
      hasSpecificPermission('showDelete', 'delete', ["delete_contracts"], this)
      await loadFromLocalStorage('contracts', 'contracts', this);
      fetchData(`contracts`, 'contracts', 'contracts', this);
    }
  }
</script>