<template>
    <BasePage>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <div v-else>
        <PageTitle :title="`${prospect.name} ${prospect.lastName} ✨`" />
        <h2 class="font-semibold text-slate-800 dark:text-slate-100 mb-1">Creado: {{formatDate(prospect.createdAt)}}</h2>
        <!-- Filters -->
        <div class="my-3 border-b border-slate-200 dark:border-slate-700">
          <div v-if="sections.length == 0">El usuario no tiene configurado poder visualizar ninguna subseccion dentro de detalle</div>
          <ul v-else class="text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
            <li
              v-for="section in sections"
              :key="section.id"
              @click="selectedSection = section.id"
              class="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
            >
              <a
                class="whitespace-nowrap dark:hover:text-slate-300"
                :class="selectedSection == section.id ? 'text-indigo-500 hover:text-indigo-600' : 'text-slate-500 hover:text-slate-600'"
                :href="section.href"
              >
                {{ section.name }}
              </a>
            </li>
          </ul>
        </div>
        <!-- Content -->
        <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
          <div v-if="selectedSection === 'section1'">
            <GeneralPanel :key="componentKey"
            :is-loading_="this.isLoading" @save="updateProspect" @force-render="forceRender" :can-edit="showEdit"
            :modify-agent="this.modifyAgent" :modify-source="this.modifySource"
            :client-todos_="this.clientTodos.filter(todo => todo.type == 'PROSPECT' && todo.clientId == this.prospectId)" :prospect="this.prospect" :categories="this.categories" />
          </div>

          <div v-else-if="selectedSection === 'section2'">
            <ProspectionDataPanel :key="componentKey" :can-edit="showEdit" :is-loading="this.isLoading" @save="updateProspect" @force-render="forceRender" :prospect="this.prospect" />
          </div>
          <div v-else-if="selectedSection === 'section3'">
            <ContractsPanel :key="componentKey" :prospect-id="this.prospectId" :client-documents_="this.clientProducts.filter(element => element.stepExtras?.length > 0 && element.clientId == this.prospectId)"/>
          </div>
          <div v-else-if="selectedSection === 'section4'">
            <ServicesPanel :key="componentKey" :can-edit="showEdit"
            :client-todos_="this.clientTodos"
            :all-products="this.products"
            :prospect-id="this.prospectId" :products_="this.clientProducts" />
          </div>
          <div v-else-if="selectedSection === 'section5'">
            <InteractionsPanel :key="componentKey" :prospect-id="this.prospectId" :client-interactions_="this.clientInteractions"/>
          </div>
          <div v-else-if="selectedSection === 'section6'">
            <DocumentsPanel :key="componentKey" :prospect-id="this.prospectId" :client-documents_="this.clientDocuments" :can-edit="showEdit"/>
          </div>
          <div v-else-if="selectedSection === 'section7'">
            <!-- <TodosPanel :key="componentKey" :list_="[...this.clientTodos.filter(todo => todo.type == 'PROSPECT').map(todo => ({ todo })), ...this.clientProducts.map(c => [...c.extras, ...c.contractExtras, ...c.stepExtras]).flat()]"/> -->
              <TodosPanel 
                @edit-todo:value="editTodo"
                :key="componentKey" :list_="this.clientTodos"/>
          </div>              
        </div>
      </div>
    </BasePage>
  </template>
  
  <script>
  import { editData } from '@/utils/editData.js';
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
  import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';
  import {extractUniquePermissions} from '@/utils/extractUniquePermissions.js';
  import {transformPermissionsToSections} from '@/utils/transformPermissionsToSections.js';

  import Swal from 'sweetalert2';
  import BasePage from '@/components/BasePage.vue';
  import api from '@/interceptors/axiosInterceptos'; 
  import PageTitle from '@/components/PageTitle.vue';
  import TodosPanel from '@/partials/prospects/detail/TodosPanel.vue';
  import GeneralPanel from '@/partials/prospects/detail/GeneralPanel.vue';
  import ServicesPanel from '@/partials/prospects/detail/ServicesPanel.vue';
  import DocumentsPanel from '@/partials/prospects/detail/DocumentsPanel.vue';
  import ContractsPanel from '@/partials/prospects/detail/ContractsPanel.vue';
  import InteractionsPanel from '@/partials/prospects/detail/InteractionsPanel.vue';
  import ProspectionDataPanel from '@/partials/prospects/detail/ProspectionDataPanel.vue';
  export default {
    name: 'ProspectDetailPage',
    components: {
      BasePage,
      PageTitle,
      TodosPanel,
      GeneralPanel,
      ServicesPanel,
      DocumentsPanel,
      ContractsPanel,
      InteractionsPanel,
      ProspectionDataPanel
    },
    data(){
        return {
          prospectOriginal: {},
          componentKey: 0,
          showEdit: false,
          modifyAgent: false,
          modifySource: false,
          prospectId: null,
          isLoading: false,
          products: [],
          categories: [],
          clientInteractions: [],
          clientDocuments: [],
          clientProducts: [],
          clientContracts: [],
          clientTodos: [],
          prospect: {},
          selectedSection: null,
          sections: [],
          clients: [],
          prospects: [],
          sources: [],
          users: []
        }
    },
    methods: {
      formatDate(dateStr) {
        if (!dateStr) return null;
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      async editTodo(formData){
        await editData(`todos/${formData.todo.id}`, {description: formData.todo.description, notes: formData.todo.notes}, 'El TO DO se ha guardado con exito.', 'clientTodos', 'clientTodos', this, false);
        this.componentKey += 1;
      },
        async forceRender(){
          this.prospect = JSON.parse(JSON.stringify(this.prospectOriginal)) || null;
          this.componentKey += 1;
        },
        async updateProspect(formData){
          try {
            this.isLoading = true;
            const token = localStorage.getItem('token');
            const headers = { 
              "authorization": `Bearer ${token}`,
            };
            const response = await api.put(`${process.env.API_URL}/prospects/${this.prospectId}`, formData, { headers });

            if(response.status == 200){
              Swal.fire({
                  icon: 'success',
                  title: 'Exito!',
                  text: 'Actualizado exitosamente.',
              });
            }
            this.isLoading = false;
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: error.response?.data?.detail ?? error.message,
            });
            this.prospect = await fetchData(`prospects/${this.prospectId}`, 'prospect', 'prospect', this, false);
            this.isLoading = false;
          }
          await loadFromLocalStorage('clientTodos', 'clientTodos', this);
          this.componentKey += 1;
        },
        async fetchClientTodos(id){
          return 
        },
        getData(id){
          Promise.all([
            fetchData(`sources`, 'sources', 'sources', this, false),
            fetchData(`users`, 'users', 'users', this, false),
            fetchData(`categories`, 'categories', 'categories', this, false),
            fetchData(`products/see`, 'products', 'products', this, false),

            fetchData(`client-interactions/${id}`, 'clientInteractions', 'clientInteractions', this, false),
            fetchData(`client-documents/prospects/${id}`, 'clientDocuments', 'clientDocuments', this, false),
            fetchData(`client-products/${id}`, 'clientProducts', 'clientProducts', this, false),
            fetchData(`todos/${id}`, 'clientTodos', 'clientTodos', this, false, false, 'data', false, true, 1000, 0)
          ]).then((results) => {
            this.componentKey++
          })
        }
    },
    async created() {
      this.isLoading = true;
      this.prospectId = this.$route.params.id;
      this.prospect = await fetchData(`prospects/${this.prospectId}`, 'prospect', 'prospect', this, false);
      this.prospectOriginal = JSON.parse(JSON.stringify(this.prospect)) || null;
      
      if(!this.prospect.isProspect){
        this.$router.push(`/clients/${this.prospectId}`);
      }
      this.stringTypeClient = this.prospect.isProspect ? 'prospects' : 'clients'
      hasSpecificPermission('showEdit', 'edit', [this.prospect.isProspect ? "edit_prospect" : 'edit_client'], this)
      hasSpecificPermission('modifyAgent', 'action', ["modify_agent_prospect_at_edit"], this)
      hasSpecificPermission('modifySource', 'action', ["modify_agent_source_at_edit"], this)
      
      const uniquePermissions = extractUniquePermissions('subsection');
      this.sections = transformPermissionsToSections(uniquePermissions, this.stringTypeClient, this)
      
      this.getData(this.prospectId)
      this.isLoading = false;
    }
  }
  </script>