<template>
  <main class="bg-white dark:bg-slate-900">

    <div class="relative flex">

      <!-- Content -->
      <div class="w-full md:w-1/2">
        <div class="min-h-[100dvh] h-full flex flex-col after:flex-1">

          <div class="flex-1">
            <div class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
              <!-- Logo -->
              <router-link class="block" to="/">
                <LogoSvg></LogoSvg>
              </router-link>
            </div>
          </div>          

          <div class="max-w-sm mx-auto w-full px-4 py-8">
            <h1 class="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Bienvenido! ✨</h1>
            <!-- Form -->
            <!-- <SliderFilter :label="'e.label'"  
                          @update:filter="()=>{}"
                          :min="Math.min(0)" :max="Math.max(1000)" 
                          :default-values_="[Math.min(0), Math.max(1000)]"></SliderFilter> -->
            <form @submit.prevent="login">
              <div class="space-y-4">
                <div>
                  <label class="block text-sm font-medium mb-1" for="email">Correo Electronico</label>
                  <input id="email" class="form-input w-full" type="email" placeholder="user@afieconsultores.com" v-model="email" required />
                </div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="password">Contraseña</label>
                  <input id="password" class="form-input w-full" type="password" autoComplete="on" placeholder="******" v-model="password" required />
                </div>
              </div>
              <div class="flex items-center justify-between mt-6">
                <div class="mr-1">
                  <router-link class="text-sm underline hover:no-underline" to="/forgot-password">¿Olvidaste tu contraseña?</router-link>
                </div>
                <SubmitMainButton :is-loading="isLoading" />
              </div>
              
            </form>
          </div>

        </div>
      </div>

      <!-- Image -->
      <div class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
        <img class="object-cover object-center w-full h-full" src="../images/Image.png" width="760" height="1024" alt="Authentication" />
        <img class="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block" src="../images/auth-decoration.png" width="218" height="224" alt="Authentication decoration" />
      </div>

    </div>

  </main>
</template>

<script>
import Swal from 'sweetalert2';

import LogoSvg from '@/components/LogoSvg.vue'
import { fetchData } from '@/utils/fetchData.js';
import api from '@/interceptors/axiosInterceptos'; 
import {hasSpecificPermission} from '@/utils/hasSpecificPermission'
import {extractUniquePermissions} from '@/utils/extractUniquePermissions'
import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'

export default {
  name: 'SigninPage',
  components: {
    LogoSvg,
    SubmitMainButton,
  },
  data() {
    return {
      me: {},
      email: '',
      password: '',
      isLoading: false,
      showCalendar: false,
    };
  },
  methods: {
    async getMe(){
      return fetchData(`users/me`, 'me', 'me', this);
    },
      async login() {
        if (this.isLoading) {
          return; // Prevent multiple submissions while loading
        }
        try {
          this.isLoading = true;
          const response = await api.post(`${process.env.API_URL}/auth/login`, {
            email: this.email,
            password: this.password,
          });
          if(response.status == 200){
            const token = response.data.token;

            // Store the token in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('user', this.email);
            localStorage.setItem('me', JSON.stringify(response.data.user))
            await this.getMe();
            hasSpecificPermission('showCalendar', 'page', ["view_calendar_page"], this)
            if(this.showCalendar){
              this.$router.push('/calendar'); // Or use a custom route if defined
            } else {
              const uniquePermissions = extractUniquePermissions('page');
              if (uniquePermissions.filter(e=> !e.params.hasDropdown).length > 0){
                this.$router.push(uniquePermissions.filter(e=> !e.params.hasDropdown)[0].params.path);
              }else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error!',
                  text: 'Rol mal configurado no cuenta con permisos para visualizar ninguna pagina o le falta configurar child en parent',
                })
              }
            }
            // Inside a component method or event handler
            this.$store.dispatch('login');
            this.isLoading = false;

          } else {
              // Handle authentication error (e.g., display error message)
              
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: response?.statusText ?? response,
              })
              this.isLoading = false;
            }
          // Handle successful authentication (e.g., store user token, redirect, etc.)
          this.isLoading = false;
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: error.response?.statusText ?? error,
          })
              
            
          // Handle authentication error (e.g., display error message)
          this.isLoading = false;
        }
      },
    },
  
}
</script>