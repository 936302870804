<template>
    <BasePage>
        <PageTitle :title="`Agregar Usuario ✨`" />
        <div class="max-w-sm mx-auto w-full px-4 py-8">

            <form @submit.prevent="addUser">
                <div class="space-y-4 mb-2">
                    <div>
                        <label class="block text-sm font-medium mb-1" for="email">Nombre de Usuario</label>
                        <input id="name" class="form-input w-full" type="text" placeholder="john_doe" v-model="user.username" required />
                    </div>
                    <div>
                        <label class="block text-sm font-medium mb-1" for="email">Correo Electronico</label>
                        <input id="email" class="form-input w-full" type="email" placeholder="user@afieconsultores.com" v-model="user.email" required />
                    </div>
                    <div>
                        <label class="block text-sm font-medium mb-1" for="password">Contraseña</label>
                        <input id="password" class="form-input w-full" autoComplete="on" placeholder="******" v-model="user.password" :type="showPassword ? 'text' : 'password'" required />
                    </div>
                    <div>
                        <button type="button" @click="togglePasswordVisibility" class="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                            {{ showPassword ? 'Ocultar' : 'Mostrar' }}
                        </button>
                        <button type="button" @click="generateRandomPassword" class="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500 ml-3">
                            Generar contraseña aleatoria
                        </button>
                    </div>
                    <div v-if="user.password.length > 0 && !isStrongPassword(user.password)" class="password-error">
                        La contraseña debe tener al menos 8 caracteres y debe incluir mayúsculas, minúsculas, un dígito y un carácter especial.
                    </div>
                    <DropdownRolesFilter :key="componentKey" :select-all_="false" label="Roles" :options="roleOptions" :model="selectedRoles" @update:model="updateUserRoles" />
                    <p v-if="!isAtLeastOneRoleSelected">Por favor seleccione al menos un rol.</p>
                </div>
                <SubmitMainButton :is-loading="isLoading" />
            </form>
        </div>
    </BasePage>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { postData } from '@/utils/postData.js';
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage, setDataInStore } from '@/utils/loadFromLocalStorage.js';
  
  import Swal from 'sweetalert2';
  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import SubmitMainButton from '@/components/buttons/SubmitMainButton.vue'
  import DropdownRolesFilter from '@/partials/users/DropdownRolesFilter.vue';
  
  export default {
    name: 'DashboardPage',
    components: {
        BasePage,
        PageTitle,
        SubmitMainButton,
        DropdownRolesFilter,
    },
    setup() {
  
      const sidebarOpen = ref(false)
  
      return {
        sidebarOpen,
      }  
    },
    data() {
        return {
            componentKey: 0,
            isLoading: false,
            roles: [],
            user: {
                username: '',
                email: '',
                password: '',
            },
            selectedRoles: {},
            showPassword: false,
            roleOptions: [],
        };
    },
    computed: {
        isAtLeastOneRoleSelected() {
        // Check if at least one role is selected
        return Object.values(this.selectedRoles).some(role => role);
        },
    },
    methods: {
        async getRoles() {
            this.roles = await fetchData(`roles`, 'roles', 'roles', this);
            this.roleOptions = this.roles.map(role => role.name);
            this.roles.map(role => this.selectedRoles[role.name] = true);
        },
        addTag (newTag) {
        const tag = {
            name: newTag,
            code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.taggingOptions.push(tag)
        this.taggingSelected.push(tag)
        },
        isStrongPassword(password) {
            // Minimum length of 8 characters
            const minLength = 8;
        
            // Check if the password meets the minimum length requirement
            if (password.length < minLength) {
                return false;
            }
        
            // Check if the password contains at least one uppercase letter
            if (!/[A-Z]/.test(password)) {
                return false;
            }
        
            // Check if the password contains at least one lowercase letter
            if (!/[a-z]/.test(password)) {
                return false;
            }
        
            // Check if the password contains at least one digit
            if (!/\d/.test(password)) {
                return false;
            }
        
            // Check if the password contains at least one special character
            if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
                return false;
            }
        
            // All criteria passed, the password is strong
            return true;
        },
        
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        generateRandomPassword() {
            const length = 12; // You can adjust the length as needed
            const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
            const digits = '0123456789';
            const specialCharacters = '!@#$%^&*(),.?":{}|<>';

            const allCharacters = uppercaseLetters + lowercaseLetters + digits + specialCharacters;

            const getRandomChar = (characters) => {
                const randomIndex = Math.floor(Math.random() * characters.length);
                return characters.charAt(randomIndex);
            };

            const randomPassword =
            getRandomChar(uppercaseLetters) +
            getRandomChar(lowercaseLetters) +
            getRandomChar(digits) +
            getRandomChar(specialCharacters) +
            // Fill the remaining characters randomly
            Array.from({ length: length - 4 }, () => getRandomChar(allCharacters)).join('');

            this.user.password = randomPassword;
        },
        toggleRoleDropdown() {
            this.showRoleDropdown = !this.showRoleDropdown;
        },
        updateUserRoles(updatedRoles) {
            this.selectedRoles = { ...updatedRoles };
        },
        async addUser() {
            if (this.isLoading) {
                return; // Prevent multiple submissions while loading
            }
            const selectedIds = [];
            let i = 0;
            for (const role in this.selectedRoles) {
                if (this.selectedRoles[role]) {
                    selectedIds.push(this.roles[i].id);
                }
                i++;
            }
            const user = {
                "username": this.user.username,
                "email": this.user.email,
                "password": this.user.password,
                "roles": selectedIds
            }
            const response = await postData('users/', user, this, true)
            if (!response) {
                return;
            };
            Swal.fire({
                icon: 'success',
                title: 'Exito!',
                text: 'El usuario se a creado exitosamente.',
            });
            this.$router.push('/users');
            const users = await readFromIdb('users');
            users.push(response)
            await setDataInStore('users', users)
        },
    },
    async created() {
        await loadFromLocalStorage('roles', 'roles', this);
        this.roleOptions = this.roles.map(role => role.name);
        this.roles.map(role => this.selectedRoles[role.name] = true);
        this.getRoles();
        this.componentKey++
    }
  }
  </script>