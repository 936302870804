import './css/style.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store/store';
import i18n from './utils/i18n';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import CKEditor from '@ckeditor/ckeditor5-vue'; 
import  VueGoogleMaps from '@fawmi/vue-google-maps'
import VueFlatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

library.add(fas)

createApp(App)
    .component('VueSlider', VueSlider)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('flat-pickr', VueFlatpickr)
    .use(router)
    .use(i18n)
    .use(store)
    .use(CKEditor)
    .use(VueGoogleMaps, {load: {
        key: "AIzaSyAcrSTew_h6kudg5zfaMGLZIRSuwjIQWFw", libraries: 'places',
        async: true,
        defer: true,
    }})
    .mount('#app')