export function prospectsPageComponentData(){
    return {
        abortController: null,
        isLoadingProspects: false,
        filtersConfig: [
            {title: 'Servicios', filters: [
              {name: 'selectedProducts', label: 'Servicios', propname: 'selectedProducts', type: 'dropdown', options: [], values: null},
            ]},
            {title: 'General', filters: [
              {name: 'name', label: 'Nombre', propname: 'nameFilter', type: 'string', options: null, values: null},
              {name: 'lastName', label: 'Apellido', propname: 'lastNameFilter', type: 'string', options: null, values: null},
              {name: 'email', label: 'Correo', propname: 'emailFilter', type: 'string', options: null, values: null},
              {name: 'curp', label: 'CURP', propname: 'curpFilter', type: 'string', options: null, values: null},
              {name: 'phone', label: 'Telefono', propname: 'phoneFilter', type: 'string', options: null, values: null},
              {name: 'dob', label: 'Fecha de Nacimiento', propname: 'dob', type: 'date', options: null, values: null},
            ]},
            {title: 'Estatus', filters: [
              {name: 'selectedRejectedOptionsFilter', label: 'Estatus', propname: 'selectedRejectedOptionsFilter', type: 'checkbox', options: ['Rechazados', 'Activos'], values: null},
              {name: 'isRejectedDate', label: 'Fecha de Rechazo', propname: 'isRejectedDate', type: 'date', options: null, values: null},
            ]},
            {title: 'Progreso', filters: [
              {name: 'selectedCallStatusFilter', label: 'Llamada', propname: 'selectedCallStatusFilter', type: 'checkbox', options: [], values: null},
              {name: 'selectedAppointmentStatusFilter', label: 'Cita', propname: 'selectedAppointmentStatusFilter', type: 'checkbox', options: [], values: null},
              {name: 'selectedClosingStatusFilter', label: 'Cierre', propname: 'selectedClosingStatusFilter', type: 'checkbox', options: [], values: null},
            ]},
            {title: 'Fuente/Vendedor', filters: [
              {name: 'selectedUsersAgent', label: 'Agentes', propname: 'selectedUsersAgent', type: 'dropdown', options: [], values: null},
              {name: 'selectedUsersSource', label: 'Fuentes', propname: 'selectedUsersSource', type: 'dropdown', options: [], values: null},
            ]},
            {title: 'Infonavit', filters: [
              {name: 'balance', label: 'Saldo Cuenta', propname: 'balance', type: 'slider', values: [0]},
              {name: 'points', label: 'Puntos', propname: 'points', type: 'slider', values: [0]}
            ]}, 
            {title: 'IMSS', filters: [
              {name: 'nss', label: 'NSS', propname: 'nssFilter', type: 'string', options: null, values: null},
              {name: 'quotedWeeks', label: 'Semanas Cotizadas', propname: 'quotedWeeksFilter', type: 'slider', values: [0]},
              {name: 'discountedWeeks', label: 'Semanas Descontadas', propname: 'discountedWeeksFilter', type: 'slider', values: [0]},
              {name: 'reintegratedWeeks', label: 'Semanas Reintegradas', propname: 'reintegratedWeeksFilter', type: 'slider', values: [0]},
              {name: 'startLaborActivityDate', label: 'Comienzo Actividad Laboral', propname: 'startLaborActivityDate', type: 'date', options: null, values: null},
              {name: 'endLaborActivityDate', label: 'Termino Actividad Laboral', propname: 'endLaborActivityDate', type: 'date', options: null, values: null},
            ]}, 
            {title: 'Credito', filters: [
              {name: 'selectedCreditSituationFilter', label: 'Situacion de Credito', propname: 'selectedCreditSituationFilter', type: 'checkbox', options: [], values: null},
              {name: 'selectedCreditTypeFilter', label: 'Tipo de Credito', propname: 'selectedCreditTypeFilter', type: 'checkbox', options: [], values: null},
              {name: 'selectedCreditStatusFilter', label: 'Estatus de Credito', propname: 'selectedCreditStatusFilter', type: 'checkbox', options: [], values: null},
            ]}, 
            {title: 'Creacion/Edicion', filters: [
              {name: 'createdAt', label: 'Fecha de Creacion', propname: 'createdAt', type: 'date', options: null, values: null},
              {name: 'updatedAt', label: 'Fecha de Edicion', propname: 'updatedAt', type: 'date', options: null, values: null}
            ]}, 
          ],
          count: 0,
          boardModeClients: true,
          boardMode: true,
          showFilters: false,
          showAdd: false,
          showEdit: false,
          showDelete: false,
          showVisualize: false,
          users: [],
          products: [],
          uniqueProducts: [],
          sources: [],
          prospects: [],
          clients: [],
          adds: [],
          componentKey: 0,
          isLoading: false,
          filters: {
            currentPage: 1,
            offset: 0,
            nameFilter: '',
            lastNameFilter: '',
            emailFilter: '',
            curpFilter: '',
            phoneFilter: '',
            nssFilter: '',
            dobStart: null,
            dobEnd: null,
            isRejectedDateStart: null,
            isRejectedDateEnd: null,
            startLaborActivityDateStart: null,
            startLaborActivityDateEnd: null,
            endLaborActivityDateStart: null,
            endLaborActivityDateEnd: null,
            createdAtStart: null,
            createdAtEnd: null,
            updatedAtStart: null,
            updatedAtEnd: null,
            balanceFilter: [],
            infonavitPointsFilter: [],
            quotedWeeksFilter: [],
            discountedWeeksFilter: [],
            reintegratedWeeksFilter: [],
            selectedRejectedOptionsFilter: {'Rechazados': true, 'Activos': true},
            selectedCallStatusFilter: {},
            selectedAppointmentStatusFilter: {},
            selectedClosingStatusFilter: {},
            selectedCreditStatusFilter: {},
            selectedCreditSituationFilter: {},
            selectedCreditTypeFilter: {},
            selectedUsersAgent: {},
            selectedUsersSource: {},
            selectedProducts: {},
        },
    }
}