import Swal from 'sweetalert2';
import api from '@/interceptors/axiosInterceptos'; 
import { getProperty } from '@/utils/getProperty.js';
import { initDB } from '../../middleware/middleware';


export async function fetchDataWithPost(
  endpoint,
  targetProperty,
  storageKey,
  context,
  body = {},
  loading = true,
  forceLoading = false,
  propertyName = 'data',
  append = false,
  isPaginated = false,
  limit = 100,
  offset = 0, 
  options = {} 
) {
  const db = await initDB();
  
  try {
    const initialLoadCompleted = localStorage.getItem(`initialLoadCompleted${targetProperty}`) === 'true';
    if (loading && (!initialLoadCompleted || forceLoading)) {
      context.isLoading = true;
    }

    const token = localStorage.getItem('token');
    const headers = { "authorization": `Bearer ${token}` };

    const paginatedEndpoint = isPaginated ? `${endpoint}/${limit}/${offset}` : endpoint;
    const response = await api.post(`${process.env.API_URL}/${paginatedEndpoint}`, body, { headers, ...options }, );
    const dataFetched = getProperty(response, propertyName);
    const isArrayResponse = Array.isArray(dataFetched);
    if (isArrayResponse) {
      context[targetProperty] = dataFetched;
      
      if(isPaginated) {
        const count = getProperty(response, 'data.count');
        context[`count`] = count
      }
    } else {
      context[targetProperty] = dataFetched;
      localStorage.setItem(storageKey, JSON.stringify(dataFetched));
    }  

    localStorage.setItem(`initialLoadCompleted${targetProperty}`, 'true');

    if (loading && (!initialLoadCompleted || forceLoading)) {
      context.isLoading = false;
    }
    return context[targetProperty];
  } catch (error) {
    if (error.name === 'CanceledError') {
      throw error;
    }
    console.log('here1212', storageKey)
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: error.response?.data?.detail ?? error.message
    });
    context.isLoading = false;
  }
}
