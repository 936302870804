<template>
  <GenericTable
    title="Servicios"
    :items="products"
    :columns="columns"
    :itemsPerPage="itemsPerPage"
    :show-pagination="true"
  >
    <template v-slot:table-rows="{ items }">
      <ProductsTableItem
        v-for="product in items"
        :key="product.id"
        :product="product"
        :value="product.id" :can-edit="canEdit" :can-delete="canDelete"
        @delete-product="deleteProduct"/>
    </template>
  </GenericTable>
</template>

<script>
  import { deleteData } from '@/utils/deleteData.js';
  
  import ProductsTableItem from './ProductsTableItem.vue'
  import GenericTable from '@/components/GenericTable.vue'
  export default {
    name: 'ProductsTable',
    components: {
      GenericTable,
      ProductsTableItem,
    },
    props: {
      products: Array,
      itemsPerPage: Number,
      canEdit: {
        type: Boolean,
        default: function(){
          return false
        }
      },
      canDelete: {
        type: Boolean,
        default: function(){
          return false
        }
      }
    },
    data() {
      return {
        isLoading: false,
        isDeleting: false,
        deletingId: '',
        columns: this.canEdit || this.canDelete ? 
          [
            { label: 'Nombre', key: 'name' },
            { label: 'Descripcion', key: 'description' },
            { label: 'Prioridad', key: 'priority' },
            { label: 'Actions', key: 'actions' }
          ]
        : [
          { label: 'Nombre', key: 'name' },
          { label: 'Descripcion', key: 'description' },
          { label: 'Prioridad', key: 'priority' },
        ],
      };
    },
    methods: {
      async deleteProduct(id) {
        const response = await deleteData('products', id, this, 'El producto se a eliminado exitosamente.', true, true)
        if(!response){
          return;
        }
        this.$emit('delete-p', id);
      },
    }
  };
</script>
